import { ReactNode } from 'react';
import { useTranslation as i8trans, Trans } from 'react-i18next';
import EN from '../../data/languages/en';

export const UseLanguageData = () => {
    const { i18n } = i8trans('translation');
    return i18n;
};

//----------------------------------------------------------------------------------------------------------------------
//TL and UseTranslationL accept any string------------------------------------------------------------------------------
interface PropsL {
    k: string,
    v?: Record<string, string | PrimitiveType | ReactNode>,
    c?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement }
}
export const TL = (props: PropsL) => {
    const i18n = UseLanguageData();
    return <Trans i18nKey={props.k} values={props.v} components={props.c} lang={i18n.language} />;
};

export const UseTranslationL = () => {
    const { t } = i8trans('translation');
    return t;
};

//----------------------------------------------------------------------------------------------------------------------
//T and UseTranslation accept only from en.ts---------------------------------------------------------------------------

//Thanks to this weird types we can't force those who try to use <T will be forced and will have autocomplete
//to fulfill with data included in EN, so only valid tags
type PathsToStringProps<T, Key extends string = ''> =
    T extends object
    ? { [K in keyof T]: PathsToStringProps<T[K], `${Key}${Key extends '' ? '' : '.'}${K & string}`> }[keyof T]
    : Key;
type ENPaths = PathsToStringProps<typeof EN>;

export const UseTranslation = () => {
    return (key: ENPaths, options?: { [key: string]: string | number | undefined }) => UseTranslationL()(key, options);
};

interface Props {
    k: ENPaths,
    v?: Record<string, string | PrimitiveType | ReactNode>,
    c?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement }
}

//T and UseTranslation only accept strings from EN
export const T = (props: Props) => TL(props);