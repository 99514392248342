import { EPlatform } from '../pages/main/e-platform';
import { UrlUtils } from '../common/utils/url-utils';

export const Links = {
    Auth: {
        ForgotPassword: '/forgot-password',
        Login: '/login',
        LoginCompose: (e: { url?: string | null, platform?: EPlatform | null, email?: string | null, lang?: string | null}) => Links.Auth.Login + UrlUtils.ComposeQueryParams({ e: e.email, p: e.platform?.toString(), u: e.url, l: e.lang }),
        Register: '/register',
        RegisterCompose: (e: { url?: string | null, platform?: EPlatform | null, lang?: string | null }) => Links.Auth.Register + UrlUtils.ComposeQueryParams({ u: e.url, p: e.platform?.toString(), l: e.lang }),
        ResetPassword: '/reset-password',
        ConfirmEmail: '/confirmEmail'
    },
    Main: {
        Default: '/',
        Youtube: '/youtube',
        Instagram: '/instagram',
        TikTok: '/tiktok',
        Generate: (platform?: EPlatform | null) => {
            switch (platform ?? EPlatform.Default) {
                case EPlatform.Default:
                    return Links.Main.Default;
                case EPlatform.Instagram:
                    return Links.Main.Instagram;
                case EPlatform.TikTok:
                    return Links.Main.TikTok;
                case EPlatform.Youtube:
                    return Links.Main.Youtube;
            }
        }
    },
    Payment: {
        Subscriptions: '/pricing',
        Success:'/pricing/paid',
        Cancel:'/pricing/failed'
    },
    Project: {
        DefaultSlug: '/project',
        YoutubeSlug: '/youtube/project',
        InstagramSlug: '/instagram/project',
        TikTokSlug: '/tiktok/project',
        Generate: (e: { projectKey: string, platform?: EPlatform | null }) => {
            let d = Links.Project.DefaultSlug;
            switch (e.platform ?? EPlatform.Default) {
                case EPlatform.Default: break;
                case EPlatform.Instagram:
                    d = Links.Project.InstagramSlug; break;
                case EPlatform.TikTok:
                    d = Links.Project.TikTokSlug; break;
                case EPlatform.Youtube:
                    d = Links.Project.YoutubeSlug; break;
            }
            return d + UrlUtils.ComposeQueryParams({ k: e.projectKey });
        },
        Failed: '/project/failed'
    },
    Settings:'/configuration'
};