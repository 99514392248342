import './menu-profile.scss';

import { Links } from '../../../../data/links';
import { A } from '../../../elemental/a';

export const MenuProfile = () => {
    return <div className="menu-profile">
        <A to={Links.Settings}
            className="configuration-header"
        >
            <span>Nombre</span>
        </A>
    </div>;
};