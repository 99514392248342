import { LayoutMiddle } from '../../components/layouts/layout-middle';
import {  useState } from 'react';
import { useAppDispatch } from '../../data/store/store';
import { AuthThunk } from '../../data/store/auth/auth-thunk';
import { ExistsEmailResponse } from '../../data/store/auth/response/exists-email-response';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../data/links';
import { ParseToEnum } from '../../common/utils/enum-utils';
import { EPlatform } from '../main/e-platform';
import { useParameters } from '../../common/utils/use-parameters';
import { RegisterUsername } from './register-username';
import { RegisterPassword } from './register-password';
import { RegisterLoading } from './register-loading';

export const Register = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [url, platform, lang] = (() => {
        const [url, platform, lang] = useParameters(['u', 'p', 'l']);
        return [url, ParseToEnum(EPlatform, platform), lang];
    })();

    const [step, setStep] = useState<number>(0);
    const [email, setEmail] = useState<string>('');

    const HandleContinue = async (email: string) => {
        setEmail(email);

        setStep(1);
        const resp = await dispatch(AuthThunk.ExistsEmail({
            email: email
        }));

        if ((resp.payload as ExistsEmailResponse)?.exists) {
            navigate(Links.Auth.LoginCompose({ email: email, platform: platform, url: url, lang: lang }));
        } else {
            setStep(2);
        }
    };

    return (
        <LayoutMiddle className="login">
            {step == 0 && <RegisterUsername key="a" onContinue={HandleContinue} defaultValue={email} />}
            {step == 1 && <RegisterLoading/>}
            {step == 2 && <RegisterPassword key="a" email={email} videoPlatform={platform} videoUrl={url} lang={lang} />}
        </LayoutMiddle>
    );
};