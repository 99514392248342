

const EN = {
    auth: {
        confirm: {
            ok: 'Confirmed account',
            error: 'Error activating account, try later'
        },
        error: {
            notAct: 'Email not validated',
            wrongUP: 'Wrong user or password',
        },
        forgot: {
            done: 'In less than 2 minuts you should receive an email. Check spam folder if you don\'t see it',
            title: 'Recover password',
            submit: 'Send',
            subtitle: 'Enter your email to send you a recovery link.'
        },
        login: {
            createAccount: 'Create your account.',
            noAccount: 'No account yet?',
            password: {
                label: 'Password',
                forgot: 'Forgot password?'
            },
            submit: 'Enter',
            subtitle: 'We are glad to see you again',
            title: 'Sign in',
            username: {
                label: 'Email',
                placeholder: 'example@email.com'
            }
        },
        register: {
            continue: 'Next',
            complete: 'Register',
            password: {
                label: 'Password',
                forgot: 'Forgot password?',
                error: {
                    PasswordRequiresNonAlphanumeric: 'Password must contain at least one special character',
                    PasswordTooShort: 'Password must be at least 6 characters long',
                    PasswordRequiresUpper: 'Password must contain one uppercase letter',
                    PasswordRequiresDigit: 'Password must contain at least one digit',
                    PasswordRequiresLower: 'Password must contain one lowercase letter',
                }
            },
            subtitle: 'It\'s quick and easy.',
            title: 'Register',
            username: {
                label: 'Email',
                placeholder: 'example@email.com'
            },
        },
        resend: {
            button: 'Send activation email again'
        },
        reset: {
            title: 'Reset password',
            subtitle: 'Choose a new password',
            submit: 'Send',
            done: 'Your new Password has been set successfully'
        }
    },
    chat: {
        dialog: {
            you: 'You',
            video: {
                status: {
                    creating: 'Creating video...',
                    composing: 'Composing video...',
                    scenes: 'Creating scenes...',
                    procesingIA: 'Processing by AI {{v}}%...',
                    starting: 'Starting...'
                }
            }
        },
        input: {
            placeholder: 'Message me and I will edit the video for you!'
        }
    },
    header: {
        pricing: 'Pricing'
    },
    main: {
        title: {
            default: 'Create videos automatically',
            tiktok: 'Create videos automatically for TikTok',
            instagram: 'Create videos automatically for Instagram',
            youtube: 'Create videos automatically for Youtube'
        },
        form: {
            url: {
                placeholder: 'Enter your website url',
                error: 'Text must be a valid web address. www.example.com'
            }
        },
        submit: 'Create video'
    },
    menu: {
        login: 'Sign in',
        newvideo: 'New Video',
        myprojects: 'My Projects',
        billing: 'Billing',
        learn: 'Learn',
        money: 'Earn money'
    },
    payments: {
        subscriptions: {
            names: {
                free: 'Free',
                pro: 'Pro',
                amateur: 'Amateur',
                enterprise: 'Enterprise'
            },
            billing: {
                0: 'Monthly',
                1: 'Biannual',
                2: 'Yearly'
            },
            quantity: '{{q}} videos per month',
            quantity1: '{{q}} video',
            scraping: 'Scraping',
            publishing: 'Publishing (coming soon)',
            music: 'Background music',
            voice: 'Narrator voice',
            aichat: 'AI chat editor',
            continue: 'Continue',
            headers: {
                startedOn: 'Started on',
                endsOn: '',
                quantity: 'Videos per month',
                name: 'Name',
                price: 'Price'
            },
            rows: {
                endsOn: 'Ends on {{d}}',
                renewOn: 'Renews on {{d}}'
            }
        },
        price: {
            perMonth: '/mo'
        }
    },
    settings: {
        logout: 'Logout'
    },
    video: {
        download: 'Download',
        errors: {
            freeUsersMax: 'Free users can\'t generate more than one video',
            quotaLimit: 'You reached your quota limit',
            unknown: 'Unknown error'
        }
    }
};

export default EN;