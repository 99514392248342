import { Progress } from 'antd';
import './tokens-brief.scss';

export const TokensBrief = () => {
    return <div className="tokens-brief">
        <div className="quantity">20/50</div>
        <div className="title">Créditos usados</div>
        <div className="subtitle">Se restaura en 17 días</div>
        <Progress percent={50} showInfo={false} />
    </div>;
};