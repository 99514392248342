import { EVideoStatus } from '../../../data/projects/e-video-status';
import { ProjectLine } from '../../../data/projects/project-line';
import { Loading } from '../../elemental/loading';
import { T } from '../../elemental/t';
import { VideoPlayer } from '../player/video-player';
import logo from '../../../assets/img/logos/logo_header.png';
import { UserOutlined } from '@ant-design/icons';

interface Props {
    line: ProjectLine;
    position: number;
    projectKey: string;
    secret: string;
}

export const ProjectChatLine = (props: Props) => {
    const { line, position, projectKey, secret } = props;
    return <div className="line">
        <div className="dialog">
            <div className="author">
                <div className="avatar">
                    <UserOutlined />
                </div>
                <T k='chat.dialog.you' />
            </div>
            <div className="text">
                {line.text}
            </div>
        </div>
        <div className="dialog">
            <div className="author">
                <div className="avatar">
                    <img src={logo} />
                </div>
                VidCreator{' '}{line.status < EVideoStatus.Finished && <Loading />}
            </div>
            <div className="text">
                {line.status >= EVideoStatus.Error && 'Error'}
                {line.status == EVideoStatus.Finished && <VideoPlayer pKey={projectKey} position={position} secret={secret} />}
                {line.status == EVideoStatus.CreatingVideo && <T k='chat.dialog.video.status.creating' />}
                {line.status == EVideoStatus.CreatingVideoComposing && <T k='chat.dialog.video.status.composing' />}
                {line.status == EVideoStatus.CreatingVideoScenes && <T k='chat.dialog.video.status.scenes' />}
                {line.status == EVideoStatus.ProcessingIA && <T k='chat.dialog.video.status.procesingIA' v={{v:line.substatus ?? 0}} />}
                {line.status == EVideoStatus.Initial && <T k='chat.dialog.video.status.starting' />}
            </div>
        </div>
    </div>;
};