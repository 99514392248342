import { Button, Form, Input, Select } from 'antd';
import { FormMiddle } from '../../form/form-middle';
import { T, UseTranslation } from '../../elemental/t';
import { useAppDispatch, useAppSelector } from '../../../data/store/store';
import { useNavigate } from 'react-router-dom';
import { UseAuth } from '../../../data/store/auth/auth';
import { EPlatform } from '../../../pages/main/e-platform';
import { Links } from '../../../data/links';
import { GenerateVideo } from '../../../common/project/generate';
import { Loading } from '../../elemental/loading';
import { languages } from '../../../data/languages/all-lang';

interface Props {
    titleText: React.ReactNode,
    platform: EPlatform
}

type FormValue = {
    url: string,
    lang: string
}

export const UrlGenerator = (props: Props) => {
    const { titleText, platform } = props;

    const { generate } = useAppSelector(x => x.Video);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = UseAuth();
    const t = UseTranslation();

    const FormSubmit = (e: FormValue) => {

        if (e.url == null || e.url.trim().length == 0)
            return;

        if (!auth.logged) {
            navigate(Links.Auth.LoginCompose({ url: e.url, platform: platform, lang: e.lang }));
            return;
        }

        GenerateVideo(dispatch, navigate, e.url, e.lang, platform);
    };

    const p = t('main.form.url.placeholder');

    return <FormMiddle onFinish={FormSubmit} className="create-video-form" >
        <Form.Item>
            <h1>
                {titleText}
            </h1>
        </Form.Item>
        {generate?.loading && <Form.Item>
            <Loading/>
        </Form.Item>}
        {!generate?.loading && <>
            <Form.Item
                name="url"
                rules={[{
                    pattern: /^(https?:\/\/)?([a-z\d-]*\.)+[a-z]{2,}(:\d+)?/i,
                    message: <T k='main.form.url.error' />
                }]}
            >
                <Input key="url" size="large" placeholder={p} className="input-url" addonAfter={
                    <Select defaultValue="es" key="lang">
                        {languages.map(lang => (
                            <Select.Option key={lang.code} value={lang.code}>
                                {lang.name}
                            </Select.Option>
                        ))}
                    </Select>
                } />
            </Form.Item>
            <Form.Item>
                <Button type="primary" size="large" htmlType="submit">
                    <T k='main.submit' />
                </Button>
            </Form.Item>
        </>}        
    </FormMiddle>;
};