export enum EVideoStatus {
    Unknown = 0,
    Initial = 1,
    ProcessingIA = 100,
    CreatingVideo = 200,
    CreatingVideoScenes = 210,
    CreatingVideoComposing = 220,
    Finished = 300,
    Error = 310,
    ErrorIA = 311,
    ErrorVideo = 312,
    ErrorVideoScenes = 313,
    ErrorVideoCompmosing = 314
}