import { useState } from 'react';
import { UrlUtils } from '../../../common/utils/url-utils';
import { API_URL } from '../../../data/com/client';
import { Links } from '../../../data/links';
import { ProjectHistoryData } from '../../../data/projects/project-history-data';
import { EPlatform } from '../../../pages/main/e-platform';
import { A } from '../../elemental/a';
import { EVideoStatus } from '../../../data/projects/e-video-status';
import { Loading } from '../../elemental/loading';

interface Props {
    project: ProjectHistoryData,
    platform: EPlatform
}
export const ProjectHistoryCard = (props: Props) => {
    const { project, platform } = props;

    const [imgError, SetImgError] = useState<boolean>(project.firstVideoStatus >= EVideoStatus.Error);

    const params = UrlUtils.ComposeQueryParams({ k: project.key, p: 1, s: project.secret });
    const src_thumb = API_URL + '/project/thumbnail' + params;

    const HandleError = () => {
        SetImgError(true);
    };

    return <A className="history-card" to={Links.Project.Generate({ projectKey: project.key, platform: platform })}>
        {props.project.firstVideoStatus >= EVideoStatus.Finished && <>
            {!imgError && <img className="thumbnail" src={src_thumb} onError={HandleError} />}
            {imgError && <div className="thumbnail error" />}
        </>}
        {props.project.firstVideoStatus < EVideoStatus.Finished && <div className="thumbnail"><Loading/></div>}
    </A>;
};