import { Button, Form, Input, Progress } from 'antd';
import { FormMiddle } from '../../components/form/form-middle';
import { T, TL, UseLanguageData } from '../../components/elemental/t';
import { Loading } from '../../components/elemental/loading';
import { useAppDispatch, useAppSelector } from '../../data/store/store';
import { AuthThunk } from '../../data/store/auth/auth-thunk';
import { useState } from 'react';
import { GenerateVideo } from '../../common/project/generate';
import { RegisterResponse } from '../../data/store/auth/response/register-response';
import { useNavigate } from 'react-router-dom';
import { EPlatform } from '../main/e-platform';
import { Links } from '../../data/links';

interface Props {
    email: string,
    videoUrl?: string | null,
    videoPlatform?: EPlatform | null,
    lang?: string | null
}

export const RegisterPassword = (props: Props) => {
    const { email, videoUrl, videoPlatform, lang } = props;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const ulang = UseLanguageData();

    const { user } = useAppSelector((state) => state.Auth);
    const [password, setPassword] = useState<string>('');

    const HandleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const error = user?.register?.error;

    let passwordError: string | null = null;
    if (error ?? '' != '') {
        passwordError = 'auth.register.password.error.' + error;
    }

    const HandleSubmit = async () => {
        if (!email || !password)
            return;
        const rp = await dispatch(AuthThunk.Register({
            email: email,
            password: password,
            lang: ulang.language
        }));
        if ((rp.payload as RegisterResponse).success && (rp.payload as RegisterResponse).token) {
            if (videoUrl) {
                navigate(Links.Main.Generate(videoPlatform));
                GenerateVideo(dispatch, navigate, videoUrl, lang, videoPlatform);
            } else {
                navigate(Links.Main.Default);
            }
        }
    };


    return <FormMiddle key='f'>
        <Form.Item>
            <h1><T k='auth.register.title' /></h1>
            <p>{email}</p>
        </Form.Item>
        <Form.Item>
            <Progress key="p" showInfo={false} percent={66} />
        </Form.Item>
        {user?.register?.loading && <Loading />}
        {!user?.register?.loading && <>
            <Form.Item
                label={<T k='auth.register.password.label' />}
                validateStatus={passwordError ? 'error' : undefined}
                help={<TL k={passwordError ?? ''} />}
            >
                <Input key='password' type='password' name='password' value={password} onChange={HandleChangePassword} />
            </Form.Item>
            <Form.Item>
                <Button onClick={HandleSubmit} htmlType="submit" type="primary"><T k='auth.register.complete' /></Button>
            </Form.Item>
        </>}
    </FormMiddle>;
};
