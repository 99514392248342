import 'antd/dist/reset.css'; //Reset default browser styles
import './app.scss';

import { Flex, Layout } from 'antd';
import { AppContent } from './content/content';
import { App as AntdApp } from 'antd';
import { AppHeader } from './header/header';
import { BrowserRouter } from 'react-router-dom';
import { FlexItem } from '../elemental/flex-item';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../data/languages/i18n';
import { Provider } from 'react-redux';
import { store } from '../../data/store/store';
import { AppMenu } from './menu/app-menu';

export const App = () => {
    return (
        <Provider store={store}>
            <AntdApp>
                <I18nextProvider i18n={i18n}>
                    <Layout>
                        <BrowserRouter>
                            <Flex>
                                <AppMenu />
                                <Flex vertical className="app-body">
                                    <AppHeader />
                                    <FlexItem grow={1} >
                                        <AppContent />
                                    </FlexItem>
                                </Flex>
                            </Flex>
                        </BrowserRouter>
                    </Layout>
                </I18nextProvider>
            </AntdApp>
        </Provider>
    );
};