import './project-history.scss';

import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../data/store/store';
import { ProjectThunk } from '../../../data/store/project/project-thunk';
import { EPlatform } from '../../../pages/main/e-platform';
import { ProjectHistoryData } from '../../../data/projects/project-history-data';
import { GetProjectHistoryResponse } from '../../../data/store/project/response/get-history-project-response';
import { ProjectHistoryCard } from './project-history-card';
import { Flex } from 'antd';

interface Props {
    platform?: EPlatform;
}

export const ProjectHistory = (props: Props) => {
    const dispatch = useAppDispatch();
    const { platform } = props;

    const [projectHistory, SetProjectHistory] = useState<ProjectHistoryData[]>();

    useEffect(() => {
        (async () => {
            const p = await dispatch(ProjectThunk.GetHistory({ platform: platform ?? EPlatform.Default }));
            SetProjectHistory((p.payload as GetProjectHistoryResponse).projects);
        })();
    }, []);

    if (!projectHistory || projectHistory.length == 0)
        return <></>;

    return <div className="history">
        <h2>Last videos</h2>
        <div className="history-list-wrap">
            <Flex className="history-list" wrap="wrap">
                {projectHistory.sort((a,b,) => b.createdOn.localeCompare(a.createdOn)).map((x, i) => <ProjectHistoryCard key={i} project={x} platform={platform ?? EPlatform.Default} />)}
            </Flex>
        </div>
    </div>;
};