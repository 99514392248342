import './app-menu.scss';

import { Flex, Menu } from 'antd';
import { AppLogo } from './logo/logo';
import { T } from '../../elemental/t';
import { Language } from './language/language';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../../data/links';
import { A } from '../../elemental/a';
import { useAppSelector } from '../../../data/store/store';
import { EuroOutlined, MenuOutlined, PlusOutlined, QuestionCircleOutlined, VideoCameraOutlined, WalletOutlined } from '@ant-design/icons';
import { MenuProfile } from './profile/menu-profile';
import { TokensBrief } from './tokens/tokens-brief';

export const AppMenu = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.Auth);

    const menu_items = [
        {
            key: '0',
            url: Links.Main.TikTok,
            icon: <PlusOutlined />,
            label: <T k='menu.newvideo' />,
        }, {
            key: '1',
            url: Links.Main.Youtube,
            icon: <VideoCameraOutlined />,
            label: <T k='menu.myprojects' />,
        }
    ];
    const menu_items_2 = [
        {
            key: '2',
            url: Links.Main.Instagram,
            icon: <WalletOutlined />,
            label: <T k='menu.billing' />,
        },
        {
            key: '3',
            url: Links.Main.Instagram,
            icon: <QuestionCircleOutlined />,
            label: <T k='menu.learn' />,
        }, {
            key: '4',
            url: Links.Payment.Subscriptions,
            icon: <EuroOutlined />,
            label: <T k='menu.money' />,
        }
    ];

    const phone_menu_items = [
        {
            key: '-1',
            icon: <MenuOutlined />,
            label: '',
            children: [
                ...menu_items,
                ...menu_items_2,
                {
                    key: 'c',
                    url: Links.Settings,
                    label: 'Nombre'
                }
            ]
        }
    ];

    const onClick = (e: { key: string }) => {
        navigate(menu_items.find(x => x.key == e.key)?.url ?? Links.Main.Default);
    };

    return <div className="app-menu">
        <Menu
            className="burger-menu"
            onClick={onClick}
            mode="horizontal"
            items={phone_menu_items}
        />
        <Flex vertical className="computer-menu" >
            <div><AppLogo /></div>
            
            <div className="language-area">
                <Language />
            </div>
            <div className="menu-area">
                <Menu
                    onClick={onClick}
                    mode="vertical"
                    items={menu_items}
                />
            </div>
            <div className="empty-area"/>
            <div className="menu-area">
                <Menu
                    onClick={onClick}
                    mode="vertical"
                    items={menu_items_2}
                />
            </div>
            <div className="tokens-area">
                <TokensBrief/>
            </div>
            <div className="profile-area">
                {!user?.logged && <A to={Links.Auth.Login}><T k="menu.login" /></A>}
                {user?.logged && <MenuProfile/>}
            </div>
        </Flex>
    </div>;
};